import GeoJSON from "ol/format/GeoJSON.js";
import Map from "ol/Map.js";
import { getCenter, getWidth } from "ol/extent";
import View from "ol/View.js";
import { OSM, Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import {Control, defaults as defaultControls} from 'ol/control.js';

const mapControls = {
  mode: "country", // country || region
  isCountryMode: () => mapControls.mode === "country",
  isRegionMode: () => mapControls.mode === "region",
  setRegionMode: () => mapControls.mode = "region",
  setCountryMode: () => mapControls.mode = "country"
}

const GEOJSON_COUNTRIES_URL = "/api/countries/africa/geojson";
const GEOJSON_REGIONS_URL = "/api/regions/$iso3/geojson";
const GEOJSON_REGIONS_KML_URL = "/api/regions/$pcode/kml";

let countryVectorLayer = null;
let regionVectorLayer = null;


function getCountriesURL () {
  return GEOJSON_COUNTRIES_URL;
}

function getRegionsURL (iso3) {
  return GEOJSON_REGIONS_URL.replace("$iso3", iso3);
}

function getRegionKMLURL (pcode) {
  return GEOJSON_REGIONS_KML_URL.replace("$pcode", pcode);
}

const AFRICA_BB = [-35.819130, -25.357025, 37.177825, 51.059261];
const INITIAL_ZOOM = 3;

(bootstrap)();

async function bootstrap () {
  function resetMap () {
    if (mapControls.isRegionMode()) {
      map.removeLayer(regionVectorLayer);
      map.addLayer(countryVectorLayer);

      mapControls.setCountryMode();
    }
  }

  class ResetControl extends Control {
    constructor(opt_options) {
      const options = opt_options || {};

      const button = document.createElement('button');
      button.innerHTML = "R";
      button.setAttribute("title", "Resets the map country view");

      const element = document.createElement('div');
      element.className = "ol-custom-reload-btn ol-unselectable ol-control";
      element.appendChild(button);

      super({
        element: element,
        target: options.target,
      });

      button.addEventListener("click", () => resetMap(), false);
    }
  }

  const map = new Map({
    controls: defaultControls().extend([new ResetControl()]),
    layers: [
      new TileLayer({
        source: new OSM(),
      }),
    ],
    target: "map",
    view: new View({
      center: getCenter(AFRICA_BB),
      zoom: INITIAL_ZOOM
    }),
  });

  const vectorSource = new VectorSource({
    format: new GeoJSON(),
    url: getCountriesURL()
  });

  countryVectorLayer = new VectorLayer({
      source: vectorSource,
  });

  map.addLayer(countryVectorLayer);

  map.on("click", event => {
    map.forEachFeatureAtPixel(event.pixel, feature => {
      // Handle the click event for the feature

      if (mapControls.isCountryMode()) {
        regionVectorLayer = new VectorLayer({
            source: new VectorSource({
              format: new GeoJSON(),
              url: getRegionsURL(feature.getProperties().iso3)
            })
        });

        map.addLayer(regionVectorLayer);
        map.removeLayer(countryVectorLayer);

        mapControls.setRegionMode();
      } else if (mapControls.isRegionMode()) {
        location.href = getRegionKMLURL(feature.getProperties().pcode);
      }
    });
  });

  document.addEventListener("keydown", event => {
    if (event.key === "Escape" || event.key === "Esc") {
      resetMap()
    }
  });
}
